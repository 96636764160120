<template>
  <div class="container">
    <div class="Backgroundfilter row">
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <b-form-group class="voertuig">
          <b-form-select v-model="type" :options="types">
            <b-form-select-option :value="null" disabled>{{
              $t("type")
            }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <b-form-group class="merk">
          <b-form-select v-model="brand" :options="brands">
            <b-form-select-option :value="null" disabled
              >&#xf21c; {{ $t("brand") }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <b-form-group class="model">
          <b-form-select v-model="model" :options="models"
            ><b-form-select-option :value="null" disabled
              >&#xf21c; {{ $t("model") }}
            </b-form-select-option></b-form-select
          >
        </b-form-group>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <b-form-group class="bouwjaar">
          <b-form-select v-model="year" :options="years">
            <b-form-select-option :value="null" disabled
              >&#xf073; {{ $t("year") }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <b-form-group class="motorinhoud">
          <b-form-select v-model="cilinder" :options="cilinders"
            ><b-form-select-option :value="null" disabled
              >&#xf2db; {{ $t("cilinder") }}
            </b-form-select-option></b-form-select
          >
        </b-form-group>
      </div>
      <div class="col-xs-12 col-md-6 col-sm-6 col-lg-2">
        <div class="GoButton">
          <button
            @click="goPartFinder()"
            v-bind:disabled="filterDisable"
            class="Button"
          >
            {{ $t("search") }}
          </button>
          <button @click="goDoReset()" class="Button">{{ $t("reset") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterSystem",
  props: { samePage: { type: Boolean, default: false } },
  methods: {
    goPartFinder() {
      if (this.samePage == true) {
        this.$store.dispatch("search/loadPartfinder");
      } else {
        this.$router.push("/partfinder");
      }
    },
    goDoReset() {
      this.$store.dispatch("partfinder/doReset");
      this.$store.commit("search/removeAllActiveFilter");
    },
  },
  computed: {
    filterDisable() {
      if (this.type != null) {
        if (this.brand != null) {
          if (this.model != null) {
            return false;
          }
        }
      }

      return true;
    },
    types() {
      return this.$store.getters["partfinder/getTypes"];
    },
    brands() {
      return this.$store.getters["partfinder/getBrands"];
    },
    models() {
      return this.$store.getters["partfinder/getModels"];
    },
    years() {
      return this.$store.getters["partfinder/getYears"];
    },
    cilinders() {
      return this.$store.getters["partfinder/getCilinders"];
    },
    type: {
      get: function () {
        return this.$store.getters["partfinder/getType"];
      },
      set: function (val) {
        return this.$store.dispatch("partfinder/putType", { type: val });
      },
    },
    brand: {
      get: function () {
        return this.$store.getters["partfinder/getBrand"];
      },
      set: function (val) {
        return this.$store.dispatch("partfinder/putBrand", { brand: val });
      },
    },
    model: {
      get: function () {
        return this.$store.getters["partfinder/getModel"];
      },
      set: function (val) {
        return this.$store.dispatch("partfinder/putModel", { model: val });
      },
    },
    year: {
      get: function () {
        return this.$store.getters["partfinder/getYear"];
      },
      set: function (val) {
        return this.$store.dispatch("partfinder/putYear", { year: val });
      },
    },
    cilinder: {
      get: function () {
        return this.$store.getters["partfinder/getCilinder"];
      },
      set: function (val) {
        return this.$store.dispatch("partfinder/putCilinder", {
          cilinder: val,
        });
      },
    },
  },
  data: () => ({
    selected: null,
    options: [
      { value: "B", text: "HONDA" },
      { value: "C", text: "SUZUKI" },
    ],
  }),
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.row.Backgroundfilter {
  background-color: #ff0000;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  > div {
    padding-left: 0;
    padding-right: 0;
  }
  .custom-select {
    font-family: FontAwesome, Gibson regular, Helvetica Neue, Arial, Noto Sans,
      sans-serif;
  }
  .GoButton {
    @include media("screen", ">=desktop") {
      margin-bottom: -38px;
      margin-top: 3px;
    }

    @include media("screen", ">=widescreen") {
      margin-bottom: -38px;
      margin-top: 3px;
    }

    .Button {
      background-color: #00893d;
      color: white;
      padding: 8px 10px;
      margin-left: 5px;
      border-radius: 10px;
      border: 1px solid #00893d;
      cursor: pointer;
    }
  }

  .form-group.voertuig {
    @include media("screen", ">=desktop") {
      margin-top: 5px;
    }

    @include media("screen", ">=widescreen") {
      margin-top: 5px;
    }

    .custom-select {
      border-radius: 10px;
    }
  }

  .form-group.merk {
    @include media("screen", ">=desktop") {
      margin-top: 5px;
    }

    @include media("screen", ">=widescreen") {
      margin-top: 5px;
    }
    .custom-select {
      border-radius: 10px;
    }
  }

  .form-group.model {
    @include media("screen", ">=desktop") {
      margin-top: 5px;
    }

    @include media("screen", ">=widescreen") {
      margin-top: 5px;
    }

    .custom-select {
      border-radius: 10px;
    }
  }

  .form-group.bouwjaar {
    @include media("screen", ">=desktop") {
      margin-top: 5px;
    }

    @include media("screen", ">=widescreen") {
      margin-top: 5px;
    }

    .custom-select {
      border-radius: 10px;
    }
  }

  .form-group.motorinhoud {
    @include media("screen", ">=desktop") {
      margin-top: 5px;
    }

    @include media("screen", ">=widescreen") {
      margin-top: 5px;
    }

    .custom-select {
      border-radius: 10px;
    }
  }
}
</style>
